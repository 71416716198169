import React from "react"
import * as Component from '../components'
import { Container, Row, Col } from "react-bootstrap"
import GoogleMaps from "../utils/googleMap"

const contact = () => {
  return (
    <Component.Layout className="page-contact">
      <Container className="p-0" fluid={true}>
        <Row>
          <Col sm={7}>
            <div className="map-container">
              <GoogleMaps />
            </div>
          </Col>
          <Col sm={5}>
            <article>
              <address>
                <p>
                  <strong>The company</strong>
                  Emirates Face Recognition LLC<br></br> PO Box 107755<br></br>
                  Abu Dhabi United Arab Emirates
                </p>
              </address>
              <aside>
                <h3>GENERAL INFORMATION</h3>
                <span>
                  <Component.Icon icon="envelope-o"></Component.Icon>info@facerecognition.ae
                </span>
              </aside>
            </article>
          </Col>
        </Row>
      </Container>
    </Component.Layout>
  )
}
export default contact
