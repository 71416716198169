import React from "react"
import GoogleMapReact from "google-map-react"
import * as Component from '../components'

const GoogleMaps = () => {

  const defaultProps = {
    center: {
      lat: 24.4287771,
      lng: 54.470002,
    },
    zoom: 15,
  }
  
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBcm_gcc9fvrZOYTu22td9ej2OMYBlVnqA" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
       <Component.Marker
          lat={24.4287771}
          lng={54.470002}
          name="Emirates Face Recognition"
          color="#f00"
        />
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMaps

